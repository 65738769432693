import {useState} from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography
} from '@mui/material';
import {useMutationAsUser, useMutationCoordinate, useQueryAsUser} from '../../clients/graphql/graphql.hooks';
import {
    GQL_JediUserEdit,
    GQL_UserEdit,
    GQL_WhoamiWithSubscriptions,
    WhoAmIBasic,
    WhoAmIWithSubscriptions
} from '../../clients/graphql/user/user-info.query';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import {UserInstitutionsList} from "../institutions/UserInstitutionsList";

export type UserCrudProps = {
    userKey: number
}
export function UserCrudPanel({ userKey }: UserCrudProps) {

    const [editing, setEditing] = useState<boolean>(false);
    const { loading, error, data, refetch } = useQueryAsUser(GQL_WhoamiWithSubscriptions, { userKey, variables: { userKey } })

    return <>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <Typography variant="subtitle2" component='div' align={"left"} sx={{
                paddingLeft: '.75rem',
                paddingTop: '.75rem',
            }}>
                DETAILS
            </Typography>
            <Button
                color="inherit"
                onClick={() => setEditing(true)}
                sx={{
                    minWidth: 'auto',
                    minHeight: 'auto',
                    height: '10'
                }}>
                <EditIcon fontSize='small' />
            </Button>
        </Box>
        {
            loading ? <CircularProgress /> :
                error ? <Alert severity="warning">{error?.message}</Alert> :
                    <NewUserCrudDisplay userKey={userKey} whoami={data?.whoami} />
        }
        {data &&
            <UserUpdateDialog isOpen={editing} handleClose={() => { setEditing(false); refetch(); }}
                              whoami={data?.whoami} userKey={userKey} />
        }
    </>
}

type NewUserCrudDisplayProps = {
    userKey: number,
    whoami: WhoAmIWithSubscriptions
}
function NewUserCrudDisplay({ userKey, whoami }: NewUserCrudDisplayProps) {

    const createdDate = new Date(whoami.createdAt);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' , paddingBottom: "1rem", borderRadius: '8px'}}>
                <Box sx={{
                    textAlign: 'left',
                    borderRadius: '8px',
                    border: '1px solid lightgray',
                    padding: '10px',
                    marginLeft: '.75rem',
                    marginTop: '.5rem',
                    marginRight: '.75rem',
                    overflowX: 'scroll'
                }}>
                    <ListItemText
                    primary={<><strong>{whoami?.firstName} {whoami?.lastName}</strong> : {whoami?.userKey}</>}/>
                    <ListItemText primary={whoami?.phoneNumber}></ListItemText>
                    <ListItemText primary={whoami?.email}/>
                    <ListItemText><strong>Created at:</strong> {createdDate.toLocaleDateString("en-US")}
                    </ListItemText>
                </Box>

                <Typography variant="subtitle2" component='div' align={"left"} sx={{
                    paddingLeft: '.75rem',
                    paddingTop: '.75rem',
                }}>
                    SUBSCRIPTIONS
                </Typography>
                {subscriptionCard(whoami)}

                <Box sx={{m: 1, p: 1}}></Box>

                <Typography variant="subtitle2" component='div' align={"left"} sx={{
                    paddingLeft: '.75rem',
                    paddingTop: '.75rem',
                }}>
                    DEVICES
                </Typography>
                {deviceCard(whoami)}

                <Box sx={{m: 1, p: 1}}></Box>

                <Typography variant="subtitle2" component='div' align={"left"} sx={{
                    paddingLeft: '.75rem',
                    paddingTop: '.75rem',
                }}>
                    INSTITUTIONS
                </Typography>
                <UserInstitutionsList userKey={userKey}/>
        </Box>
    )
}

type UserUpdateDialotProps = {
    userKey: number,
    isOpen: boolean,
    handleClose: any,
    whoami: WhoAmIBasic
}
function UserUpdateDialog({ userKey, isOpen, handleClose, whoami }: UserUpdateDialotProps) {

    const [firstName, setFirstName] = useState<String>(whoami.firstName);
    const [lastName, setLastName] = useState<String>(whoami.lastName);
    const [email, setEmail] = useState<String>(whoami.email);
    const [phoneNumber, setPhoneNumber] = useState<String>(whoami.phoneNumber);
    const [userEditMutation, userEditState] = useMutationAsUser(GQL_UserEdit, {
        userKey,
        variables: {
            firstName,
            lastName,
            email
        }
    })
    const [jediUserEditMutation, jediUserEditState] = useMutationCoordinate(GQL_JediUserEdit, {
        variables: {
            userKey: parseInt(userKey.toString()), // No idea why but this fixes some casting issues
            phoneNumber
        }
    })

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await Promise.all([
                userEditMutation(),
                jediUserEditMutation()
            ]);
            handleClose();
        } catch (e) {
            // eat and render the error
        }
    }

    const loading = userEditState?.loading || jediUserEditState?.loading;

    return <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
            component: 'form',
            onSubmit: handleSubmit,
        }}
    >
        <DialogTitle>Edit Customer</DialogTitle>
        {userEditState?.error &&
            <Alert severity="warning">{userEditState?.error?.message}</Alert>
        }
        {jediUserEditState?.error &&
            <Alert severity="warning">{jediUserEditState?.error?.message}</Alert>
        }
        <DialogContent >
            <TextField
                autoFocus
                required
                margin="dense"
                id="firstName"
                name="firstName"
                label="First Name"
                type="text"
                fullWidth
                variant="standard"
                value={firstName}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setFirstName(e.target.value)}
            />
            <TextField
                autoFocus
                required
                margin="dense"
                id="lastName"
                name="lastName"
                label="Last Name"
                type="text"
                fullWidth
                variant="standard"
                value={lastName}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setLastName(e.target.value)}
            />
            <TextField
                autoFocus
                required
                margin="dense"
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                type="phone"
                fullWidth
                variant="standard"
                value={phoneNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setPhoneNumber(e.target.value)}
            />
            <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                name="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setEmail(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} disabled={loading}>Cancel</Button>
            <Button type="submit" variant='contained' disabled={loading}>Update User</Button>
        </DialogActions>
    </Dialog>
}

function subscriptionCard(whoami: WhoAmIWithSubscriptions){
        return(
        <Box sx={{
            borderRadius: '8px',
            border: '1px solid lightgray',
            padding: '10px',
            marginLeft: '.75rem',
            marginTop: '.5rem',
            marginRight: '.75rem',
        }}>

            {whoami.subscription && whoami.subscription.products.map((product: any, index: number) => {
                return <ListItem key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: "flex-start"}}disablePadding>
                        <ListItemText primary={<strong>{product.name}</strong>} />
                        <ListItemText primary={`$${product.prices[0].unitAmount / 100} ${product.prices[0].displayInterval}`} />
                        <ListItemText
                            primary={
                            <Typography variant="subtitle2">
                                {`SUBSCRIBED SINCE: ${moment(whoami.subscription.activatedAt).format('MM/DD/YYYY HH:mm')}`}
                        </Typography>
                            }
                        />
            </ListItem>
            }, [])}
        </Box>
        );
}

function deviceCard(whoami: WhoAmIWithSubscriptions){
    return (
        <Box sx={{
            borderRadius: '8px',
            border: '1px solid lightgray',
            justifyContent: 'space-between',
            textAlign: 'left',
            paddingLeft: '0.9rem',
            marginLeft: '.75rem',
            marginTop: '.5rem',
            marginRight: '.75rem',
        }}>
            <ListItemText><strong>{whoami?.metadata?.deviceOS}</strong>: {whoami?.metadata?.deviceOSVersion}</ListItemText>
            <ListItemText><strong>App Version:</strong> {whoami?.metadata?.appVersionName}</ListItemText>
        </Box>
    );
}