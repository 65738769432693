import React from 'react';
import '../home/css/home-screen-style.css';
import {Typography} from '@mui/material';
import {UserHoldingsTable} from '../../../components/tables/UserHoldingsTable';

export const SlaveDetailScreen = ({ slavePortfolio, userKey, slaveDataByAutopilotSetting }) => {

    if (!slavePortfolio || !slaveDataByAutopilotSetting) {
        return (
            <Typography variant="p" component="div">
                Please select a portfolio card to view details
            </Typography>
        );
    }
    return (
        <div className="page-view">
            <div className="detail-view" style={{ height: '100%', overflow: 'hidden' }}>
                {
                    <div style={{ width: '100%', height: 'calc(100vh - 94px)', overflow: 'auto' }}>
                        <UserHoldingsTable holdings={slavePortfolio.currentPositions.concat(( slavePortfolio.autoPilotCashHolding?.marketValue!==0.0?[{
                            symbol: slavePortfolio.autoPilotCashHolding?.name,
                            name: slavePortfolio.autoPilotCashHolding?.name,
                            pictureUrl: "https://storage.googleapis.com/iris-main-prod/assets/symbol-pictures/cashapplogo.png",
                            marketValue: slavePortfolio.autoPilotCashHolding?.marketValue,
                            quantity: 0.0,
                            percentOfPortfolio: slavePortfolio.autoPilotCashHolding?.percentOfPortfolio,
                            currentPrice: 0.0,
                            unrealizedProfitLoss: 0.0,
                            unrealizedProfitLossPercent: 0.0
                        }]:[]))} />
                    </div>
                }
            </div>
        </div>
    );
};
