import React from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography
} from '@mui/material';
import {useQueryCoordinate} from '../../clients/graphql/graphql.hooks';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import moment from 'moment';
import {gql} from "@apollo/client";
import WifiIcon from '@mui/icons-material/Wifi';
import BadgeIcon from '@mui/icons-material/Badge';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import {useNavigate} from "react-router-dom";
import {CONNECTION_STATUS} from '../../shared/portfolio-types';
import {DisconnectedStatus} from "../../components/status/DisconnectedStatus";
import {ConnectedStatus} from "../../components/status/ConnectedStatus";

export type props = {
    institutionKey: number
}

export default function InstitutionCrudPanel({institutionKey}: props) {

    const goTo = useNavigate()

    const getInstitutionDetails = gql`
    query whoisit($institutionKey: Int!){
        whoisit(
            input: {
                institutionKey: $institutionKey,
            }) {
                  institution {
                  institutionKey
                  userKey
                  createdAt
                  connectionStatus
                  institutionName
                  user{
                    firstName
                    lastName
                  }
                  portfolios{
                    user{
                      firstName
                      lastName
                    }
                    portfolioName
                  }
            }
        }
    }`;

    const {loading, error, data} = useQueryCoordinate(getInstitutionDetails, {variables: {institutionKey: institutionKey}});

    if (loading) {
        return <CircularProgress/>
    }

    if (error) {
        return <Alert severity="warning">{error?.message}</Alert>
    }
    const curatedData = data?.whoisit?.institution;

    return(
    <Paper elevation={4} style={{height: 400, width: '100%'}}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: 2, paddingRight: 2, paddingTop: 1.5 }}>
            <Typography variant="h6" component='div' >
                Overview
            </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box>
            <List>
                <ListItem>
                    <ListItemIcon>
                        <Person2OutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={`User: ${curatedData?.user?.firstName} ${curatedData?.user?.lastName}`} />
                    <Button
                        component="label"
                        role={undefined}
                        variant="outlined"
                        tabIndex={-1}
                        color='primary'
                        endIcon={<ArrowForwardOutlinedIcon />}
                        onClick={() => goTo(`/users/${curatedData?.userKey}`)}
                    >
                        #{curatedData?.userKey}
                    </Button>
                </ListItem>
                <Divider sx={{ my: 1 }} />
                <ListItem>
                    <ListItemIcon>
                        <WifiIcon/>
                    </ListItemIcon>
                    Status:&nbsp;
                    {curatedData?.connectionStatus === CONNECTION_STATUS.CONNECTED ? <ConnectedStatus /> : <DisconnectedStatus />}
                </ListItem>
                <Divider sx={{ my: 1 }} />
                <ListItem>
                    <ListItemIcon>
                        <BadgeIcon/>
                    </ListItemIcon>
                    <ListItemText primary={`Institution Name:  ${curatedData.institutionName}`} />
                </ListItem>
                <Divider sx={{ my: 1 }} />
                <ListItem>
                    <ListItemIcon>
                        <ChildCareIcon />
                    </ListItemIcon>
                    <ListItemText primary={`Created At:  ${moment(curatedData?.createdAt).format('MM/DD/YYYY')}`} />
                </ListItem>
            </List>
        </Box>
    </Paper>)
}