import React from 'react';
import {DataGrid, GridRenderCellParams} from '@mui/x-data-grid';
import {useNavigate} from "react-router-dom";

export const UserHoldingsTable: React.FC<{ holdings: any[] }> = ({ holdings }) => {

    let goTo = useNavigate();

    const columns = [
        {
            field: 'pictureUrl',
            headerName: 'Stock',
            headerClassName: 'table-header',
            renderCell: (params: GridRenderCellParams) => (
                <div style={{ display: 'flex', alignItems: 'left', height: '100%' }} onClick={() => goTo('/assets/' + params.row.symbol)}>
                    <img src={params.row.pictureUrl} alt="Logo" style={{ width: 'auto', height: '50%', borderRadius: '50%', alignSelf: 'center' }} />
                    <span style={{marginLeft: 10}}>{params.row.symbol}</span>
                </div>
            )
        },
        {
            field: 'marketValue',
            headerName: 'Market Value',
            headerClassName: 'table-header',
            renderCell: (params: GridRenderCellParams) => (
                <div>
                    ${params.value}
                </div>
            ),
        },
        {
            field: 'percentOfPortfolio',
            headerName: '% Alloc',
            headerClassName: 'table-header',
            renderCell: (params: GridRenderCellParams) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {(params.value * 100).toFixed(0)}%
                </div>
            ),
        },
        {
            field: 'unrealizedProfitLoss',
            headerName: 'Profit',
            headerClassName: 'table-header',
            width: 90,
            renderCell: (params: GridRenderCellParams) => (
                <div style={{ color: (params.value < 0) ? 'red' : '#4caf50' }}>
                    ${params.value}
                </div>
            ),
        },
        {
            field: 'unrealizedProfitLossPercent',
            headerName: '% Change',
            headerClassName: 'table-header',
            width: 90,
            renderCell: (params: GridRenderCellParams) => (
                <div style={{ color: (params.value < 0) ? 'red' : '#4caf50' }}>
                    {(params.value * 100).toFixed(0)}%
                </div>
            ),
        },
    ];

    return (
        <div style={{ height: 500, width: '100%' }}>
            <DataGrid
                rows={holdings}
                columns={columns}
                rowHeight={40}
                hideFooter={true}
                rowCount={holdings.length}
                getRowId={(row) => row.id || Math.random().toString(36).substr(2, 9)}  // Ensure each row has a unique ID
                initialState={{
                    sorting: {
                        sortModel: [
                            { field: 'percentOfPortfolio', sort: 'desc' },
                        ],
                    },
                }}
            />
        </div>
    );
};
